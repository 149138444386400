<template>
  <div class="riwayat-wrapper">
    <template v-if="pembayaran.length == 0">
          <div class="container-payment" style="display: none;">
        <el-card class="payment-card">
          <el-row>
            <span>Silahkan lakukan Pembayaran Formulir Pendaftaran Sebesar</span>
          </el-row>
          <el-row>
            <ul class="list-info">
            <li>
              <span>Jumlah:</span> <b>&nbsp;Rp 150.000&nbsp;</b>
            </li>
            <li>
              <span>Nama Bank:</span> <b>&nbsp;Bank BNI&nbsp;</b>
            </li>
            <li>
              <span>No. Rekening:</span> <b>&nbsp;3005555504&nbsp;</b>
            </li>
            <li>
              <span>Atas Nama:</span> <b>&nbsp;Yayasan Ma'had Darun Najah As-Salafy&nbsp;</b>
            </li>
          </ul>
          </el-row>
      
          <el-row>
            <div class="text-center">
                    <el-button round @click="handlePembayaran" type="primary">
                      Upload Bukti Sekarang
                    </el-button>
            </div>
          </el-row>
        </el-card>
      </div>
    </template>
    <template v-else-if="pembayaran[0].is_verified == 'Belum Dikonfirmasi'">
      <el-row :gutter="20">
        <el-col :span="16" :offset="4">
          <el-card shadow="always">
            <div>
              <span>
                Pembayaran anda belum dikonfirmasi, mohon tunggu konfirmasi pembayaran dari kami, terimakasih!
              </span>
            </div>
          </el-card>

        </el-col>

      </el-row>
    </template>
    <div class="riwayat-header">
      <h1 class="riwayat-title">Riwayat Pendaftaran</h1>

      <!-- <template v-if="pembayaran.length > 0"> -->
        <el-button round @click="handlePPBD" type="primary">
          Daftar Sekarang
        </el-button>
      <!-- </template> -->

    </div>
    <div class="riwayat-body">
      <el-table class="table margin-top" :data="riwayat" border>
        <el-table-column min-width="200" label="Calon Siswa">
          <div class="desc" slot-scope="item">
            <el-image style="width: 62px; height: 62px" :src="item.row.file_foto" fit="fill"></el-image>
            <div>
              <h4 class="margin-remove">{{ item.row.nama }}</h4>
              <small>NIK : {{ item.row.nik }}</small>
            </div>
          </div>
        </el-table-column>
        <el-table-column min-width="100" label="Sekolah Asal">
          <template slot-scope="item">
            <h4 class="margin-remove">{{ item.row.sekolah_asal_nama }}</h4>
            <small>{{ item.row.sekolah_asal_alamat }}</small>
          </template>
        </el-table-column>
        <el-table-column width="180" label="Status Pendaftaran">
          <template slot-scope="item">
            <el-tag>{{ item.row.status_ppdb }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="80" label="Jenis Siswa">
          <template slot-scope="item">
            <el-tag :type="item.row.status_siswa == 'Baru' ? 'success' : 'warning'">{{ item.row.status_siswa }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="80" label="Jenis Kelamin" prop="jenis_kelamin">
        </el-table-column>
        <el-table-column width="200" label="Deskripsi">
          <template slot-scope="scope">
            <el-alert v-if="scope.row.keterangan_ppdb !== ''" :title="scope.row.keterangan_ppdb" type="error"
              effect="dark"></el-alert>
            <span v-else> - </span>
          </template>
        </el-table-column>
        <el-table-column width="150" align="center" label="Aksi">
          <template slot-scope="scope">
            <el-button :disabled="scope.row.status_ppdb == 'Sudah Verifikasi'" @click="handleEdit(scope)" plain
              icon="el-icon-edit">Ubah Data</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "riwayat",
  async mounted() {
    this.forceRefresh();
    await this.fetch();
    await this.fecthpembayaran();
  },
  created() {
    this.forceRefresh();
    this.fecthpembayaran();
  },
  computed: {
    ...mapGetters({ token: "getToken", riwayat: "getRiwayat", pembayaran: "getPembayaran" }),
  },
  methods: {
    forceRefresh(){
      this.$forceUpdate();
    },
    async fetch() {
      this.loading = true;
      await this.$store.dispatch("listPendaftaran", this.token);
    },
    async fecthpembayaran() {
      this.loading = true;
      await this.$store.dispatch("listPembayaran", this.token);
    },
    handlePPBD() {
      this.$axios
        .get(process.env.VUE_APP_BASE_API + "api/v1/ppdb/ppdbstatus")
        .then((res) => {
          if (res.data.data.isi == "buka") {

            this.$router.push({ name: "Ppdb" });
          } else if (res.data.data.isi == "tutup") {
            this.$message({
              message: "Pendaftaran belum kami buka",
              type: "warning",
            });
          }
        });
    },
    handlePembayaran() {
      this.$axios
        .get(process.env.VUE_APP_BASE_API + "api/v1/ppdb/ppdbstatus")
        .then((res) => {
          if (res.data.data.isi == "buka") {
            this.$router.push({ name: "Pembayaran" });
          } else if (res.data.data.isi == "tutup") {
            this.$message({
              message: "Pendaftaran belum kami buka",
              type: "warning",
            });
          }
        });
    },
    handleEdit(item) {
      this.$store.commit("setSelectedRiwayat", item.row);
      this.$router.push({ name: "Edit-Ppdb", params: { id: item.row.uuid } });
    },
  },
};
</script>

<style lang="scss" scoped>
.riwayat {
  &-wrapper {
    padding: $space 4%;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-title {
    font-size: 2.5rem;
    margin: $space * 2 0;
    padding-bottom: $space;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80px;
      height: 5px;
      background-color: $green-color;
    }
  }
}

.desc {
  display: flex;
  gap: $space;

  >div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.text-center {
  margin-top:10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-payment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-card {
  width: 100%;
}

.list-info {
  padding: 0;
  text-align: left;

  li {
    border: 1px solid darken(white, 10);
    list-style: none;
    padding: $space;

    h4 {
      margin: 0;
    }
  }
}
</style>
